<template>
    <div>
		<el-form ref="queryForm" class="query-box"  v-model="queryParams" label-width="82px">
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="SN" prop="sn">
						<el-input v-model="queryParams.sn"
								  placeholder="请输入SN" clearable
								  size="small" @keyup.enter.native="handleQuery"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="上游渠道" prop="channelCode">
						<el-select v-model="queryParams.channelCode" size="small" placeholder="请选择上游渠道" style="width: 100%">
							<el-option label="全部" value=""></el-option>
							<el-option v-for="(item,index) in acqChannelList" :label="item.name" :value="item.code" :key="index"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="消息类型" prop="dataType">
						<el-select v-model="queryParams.dataType" clearable size="small" placeholder="请选择消息类型" style="width: 100%">
							<el-option label="全部" value=""></el-option>
							<el-option v-for="(value,key) in dicts.CHANNEL_DATA_PUSH_TYPE" :key="key" :label="value" :value="key"/>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="上游订单号" prop="outOrderNo">
						<el-input v-model="queryParams.outOrderNo"
								  placeholder="请输入上游订单号" clearable
								  size="small" @keyup.enter.native="handleQuery"/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="上游商户号" prop="outMerchantNo">
						<el-input v-model="queryParams.outMerchantNo"
								  placeholder="请输入上游商户号" clearable
								  size="small" @keyup.enter.native="handleQuery"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="推送时间">
						<el-date-picker v-model="createDateRange"
										size="small" value-format="yyyy-MM-dd"
										type="daterange" range-separator="-"
										style="width: 100%"
										:start-placeholder="translate('COMMON.START_DATE')"
										:end-placeholder="translate('COMMON.END_DATE')"/>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="状态" prop="status">
						<el-select v-model="queryParams.status" clearable size="small" placeholder="请选择状态" style="width: 100%">
							<el-option label="全部" value=""></el-option>
							<el-option v-for="(value,key) in dicts.CHANNEL_PUSH_STATUS" :key="key" :label="value" :value="key"/>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{translate('OPERATION.QUERY')}}</el-button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {TerminalApi} from '@/api';
    export default {
        name: "ToolBar",
		props: {
			dicts: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				queryParams: {},
				createDateRange: [],
				updateDateRange: [],
				showCustomForm: false,
				acqChannelList: []
			}
		},
		async mounted() {
			const result = await TerminalApi.acqChannel.listAllChannel();
			this.acqChannelList = (result && result.data) || [];
		},
		methods: {
			handlerCustomFormOnclose() {
				this.showCustomForm = false;
				this.commitChange();
			},
			handleQuery() {
				this.commitChange();
			},
			handleResetQuery(){
				this.queryParams = {};
				this.available = "";
				this.createDateRange = [];
				this.updateDateRange = [];
				this.commitChange();
			},
			handleAdd(){
				this.showCustomForm = true;
			},
			dealWithQueryParams() {
				if(!this.createDateRange){
                    this.createDateRange =[]
                }
				
				let [startCreateTime, endCreateTime] = this.createDateRange;
				this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
				this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');
			},
			commitChange() {
				this.dealWithQueryParams();
				let params = {...this.queryParams};
				this.$emit('on-change', params);
			}
		}
    }
</script>

<style scoped>

</style>
